.footer-table {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.value-column {
    text-align: right;
    font-weight: 700;
    padding-right: 8px;
}

.table-book {
    width: 100%;
    margin-bottom: 20px;
}
.row-table-book {
    
}

.table-book .ant-table-tbody {
    padding: 0 !important;
}

.table-book .ant-table-tbody>tr>td {
    padding: 0 !important;
}

.txt-title-footer {
    font-size: 1rem;
    font-weight: 800;
}
.format-number-table {
    font-size: 1rem;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: right;
    padding-right: 6px;
}
.min-height {
    min-height: calc(100vh - 220px);
}